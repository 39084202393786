import React, {Component, useEffect} from 'react';

interface Credential {
    clientId: string;
    clientSecret: string;
    tenantId: string;
}

interface Accounting365Props {
    data: Credential[];
}


const getAccessToken = async (clientId: string, clientSecret: string, tenantId: string) => {
    const tokenUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
    const tokenData = {
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
        scope: 'https://graph.microsoft.com/.default'
    };

    return fetch(tokenUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(tokenData)
    })
        .then(response => response.json())
        .then(data => data.access_token)
        .catch(error => {
            console.error('Error fetching access token:', error);
            return null;
        });
};



const Accounting365: React.FC<Accounting365Props> = ({ data }) => {

    const handleGetAccessToken = async (credential: Credential) => {
        const accessToken = await getAccessToken(credential.clientId, credential.clientSecret, credential.tenantId);
        console.log('Access Token:', accessToken);
        // Здесь вы можете использовать accessToken для чего угодно
    };

    useEffect(() => {
        // При каждом изменении данных вызываем handleGetAccessToken с последним объектом Credential
        if (data.length > 0) {
            handleGetAccessToken(data[data.length - 1]);
        }
    }, [data]);
    return (
        <div>
            <h2>Accounting365 Credentials:</h2>
            <ul>
                {data.map((credential, index) => (
                    <li key={index}>
                        <strong>Client ID:</strong> {credential.clientId}<br />
                        <strong>Client Secret:</strong> {credential.clientSecret}<br />
                        <strong>Tenant ID:</strong> {credential.tenantId}<br />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Accounting365;
