import './App.css';
import React, {useState} from 'react';
import {Select} from "antd";

import  credentials from "./data/credential.json"
import Accounting365 from "./components/accounting365"


function App() {
    const [selectedCredential, setSelectedCredential] = useState<{ clientId: string; clientSecret: string; tenantId: string; } | null>(null);

    const handleChange = (value: string) => {
        const index = parseInt(value, 10);
        const credential = credentials[index];
        setSelectedCredential({
            clientId: credential.client_id,
            clientSecret: credential.client_secret,
            tenantId: credential.tenant_id
        });
    };

    const options = credentials.map((data, index) => ({
        value: index,
        label: data.name
    }));


  return (
    <div className="App">

      <Select
          defaultValue="Select Company"
          style={{ width: 120 }}
          onChange={handleChange}
          options={options}
      />

        {selectedCredential && <Accounting365 data={[selectedCredential]} />}

    </div>
  );
}

export default App;
